@import '../../styles/utils/__utils';

.wrapper {
  display: flex;
  align-items: center;
  @media only screen and (max-width: $breakpoint-small) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: $spacing-small;
  }
  .toggle {
    display: flex;

    margin-right: $spacing-small;
    padding: $spacing-xx-small;
    width: 78px;

    cursor: pointer;

    border: none;
    border-radius: $border-radius;

    background-color: $color-light-blue;
    .ball {
      width: 50%;

      border-radius: 100%;

      background-color: $color-dark-gray;

      transition: 0.2s;
      &:after {
        display: block;

        padding-bottom: 100%;

        content: '';
      }
    }
    &.active {
      .ball {
        background-color: $color-blue;

        transform: translateX(100%);
      }
    }
  }
}
