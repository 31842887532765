@import '../../styles/utils/__utils';

.timer {
  position: relative;

  width: 100%;
  height: 6px;

  border-radius: $border-radius;

  background-color: $color-gray;
  .time {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;

    border-radius: $border-radius;

    background-color: $color-blue;
  }
}
