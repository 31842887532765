// Fonts -----------------------------------------------------------------------
$root-font-family: 'Poppins', sans-serif;
$root-font-size: 100%;
$root-line-height: 1.5;
$root-font-weight: 400;

// Breakpoint ------------------------------------------------------------------
$breakpoint-max: 1680px;
$breakpoint-large: 1080px;
$breakpoint-medium: 900px;
$breakpoint-mobile: 900px;
$breakpoint-small: 768px;
$breakpoint-tiny: 350px;

// Page sizing -----------------------------------------------------------------
$content-width: 800px;
$text-width: 600px;
$modal-width: 400px;
$page-width: 1200px;
$form-width: 400px;

// Border radius ---------------------------------------------------------------
$border-radius: 25px;

// Colors ----------------------------------------------------------------------
$color-black: #222222;
$color-white: #ffffff;

$color-glass: #21242b9d;
$color-light-blue: #f1f3f7;
$color-gray: #dce0ea;
$color-dark-gray: #a5acbb;
$color-blue: #2972f6;
$color-orange: #ffdcbb;
$color-yellow: #ffd748;
$color-green: #a1e8c1;
$color-light-green: #c1efd6;
$color-red: #ff6666;

// Effects ----------------------------------------------------------------------
$box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
$blur: blur(10px);

// Font-size -------------------------------------------------------------------
$font-size-x-small: 14px;
$font-size-small: 16px;
$font-size-basis: 18px;
$font-size-medium: 20px;
$font-size-large: 26px;
$font-size-x-large: 34px;
$font-size-xx-large: 48px;

// Spacing ---------------------------------------------------------------------
$spacing-xx-small: 4px;
$spacing-x-small: 8px;
$spacing-small: 16px;
$spacing-medium: 24px;
$spacing-large: 32px;
$spacing-x-large: 48px;
$spacing-xx-large: 56px;
