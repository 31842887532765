@import '../../styles/utils/__utils';

.mute {
  position: absolute;
  top: 0;
  right: 0;

  margin: $spacing-medium;

  z-index: 10000;
}
