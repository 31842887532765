@import '../../styles/utils/__utils';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $spacing-x-small;

  padding: $spacing-x-small $spacing-medium;

  cursor: pointer;

  font-size: $font-size-small;
  font-weight: bold;

  color: $color-white;
  text-decoration: none;

  border: 2px solid;
  border-color: $color-blue;
  border-radius: $border-radius;

  background-color: $color-blue;

  &.secondary {
    color: $color-blue;

    background-color: transparent;
  }
  &.onlyIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    width: calc(#{$spacing-medium} * 2);
    height: calc(#{$spacing-medium} * 2);

    border-radius: 100px;
    .icon {
      margin: 0;

      font-size: $font-size-large;
    }
  }
  &.small {
    gap: $spacing-xx-small;

    padding: $spacing-xx-small $spacing-x-small;
  }

  &:disabled {
    cursor: not-allowed;

    color: $color-dark-gray;

    border-color: $color-light-blue;

    background-color: $color-light-blue;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: $font-size-medium;

    line-height: 1em;
  }
}
