@import '../../styles/utils/__utils';

.content {
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: $spacing-x-large;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      margin-bottom: $spacing-xx-small;

      font-size: $font-size-large;

      text-align: center;
    }
    p {
      text-align: center;
    }
    .form {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      gap: $spacing-small;

      margin-top: $spacing-small;
      .phone {
        display: flex;
        gap: $spacing-x-small;
        .country {
          max-width: 72px;
        }
        .number {
          max-width: 160px;
        }
      }
      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $spacing-small;

        margin-top: $spacing-medium;
      }
    }
  }
}
