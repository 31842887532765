@import '../../styles/utils/__utils';

.image {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  overflow: hidden;

  border-radius: 100%;

  background-color: $color-light-blue;

  object-fit: cover;
  svg {
    position: absolute;

    width: 80%;
    height: 80%;

    color: $color-dark-gray;
  }
}
