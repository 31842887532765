@import '../../styles/utils/__utils';

$dotSize: 30px;

.wrapper {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  .map {
    position: absolute;
    top: -30px;
    left: 0;

    width: 100%;
    height: calc(100% + 60px);

    overflow: hidden;
  }
  .marker {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 30px;
    height: 30px;

    pointer-events: none;

    transform: translateX(-50%) translateY(-100%);

    &:after {
      position: absolute;

      width: 100%;
      height: 100%;

      content: '';

      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;

      background-color: $color-red;
      box-shadow: $box-shadow;

      transform: rotate(135deg);
    }
  }
}
