@import '../../../styles/utils/__utils';

.animateIn {
  animation-name: intro;
  animation-duration: 0.5s;
  animation-iteration-count: 1;

  animation-fill-mode: forwards;
  &.animateTop {
    animation-name: introTop;
  }
  @keyframes intro {
    0% {
      opacity: 0;

      transform: translateY(20px);
    }
    100% {
      opacity: 1;

      transform: translateY(0px);
    }
  }
  @keyframes introTop {
    0% {
      opacity: 0;

      transform: translateY(-20px);
    }
    100% {
      opacity: 1;

      transform: translateY(0px);
    }
  }
}

.question {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  .map {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: $color-light-blue;
  }
  .answer {
    .header {
      position: absolute;
      top: 0;
      left: 0;

      padding: $spacing-large;
      width: 100%;

      pointer-events: none;
      .question {
        position: relative;

        margin: auto;
        padding: $spacing-small;
        max-width: $text-width;

        text-align: center;

        border-radius: $border-radius;

        background-color: $color-white;
        box-shadow: $box-shadow;
        .title {
          margin-bottom: $spacing-small;

          font-size: $font-size-basis;
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 0;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: $spacing-large;
      width: 100%;
    }
  }
  .modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: $spacing-large $spacing-medium;
    width: 100%;
    max-width: $modal-width;

    border-radius: $border-radius;

    background-color: $color-white;
    box-shadow: $box-shadow;
    .title {
      font-size: $font-size-medium;
    }
  }
}
