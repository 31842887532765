@import '../../../styles/utils/__utils';

.introduction {
  display: flex;
  flex-direction: column;

  margin: $spacing-small;
  width: 100%;
  max-width: $modal-width;

  overflow: hidden;

  border-radius: $border-radius;

  background-color: $color-white;
  box-shadow: $box-shadow;
  .image {
    position: relative;

    width: 100%;

    &::after {
      display: block;

      padding-bottom: 60%;

      content: '';

      background-color: $color-gray;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
  .text {
    padding: $spacing-medium;
    .questions {
      margin-bottom: $spacing-medium;

      font-size: $font-size-x-small;
    }
    .title {
      margin-bottom: $spacing-xx-small;

      font-size: $font-size-large;
    }
    .description {
      margin-bottom: $spacing-large;

      font-size: $font-size-small;
    }
    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
    }
  }
}
