@import '../../styles/utils/__utils';

.error {
  margin: $spacing-medium 0;
  padding: $spacing-x-small $spacing-small;

  font-size: $font-size-x-small;

  color: $color-white;

  border-radius: 100px;

  background-color: $color-red;
}
