@import '../../styles/utils/__utils';

.wrapper {
  position: relative;

  .code {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $spacing-small;
    @media only screen and (max-width: $breakpoint-medium) {
      gap: $spacing-x-small;
    }
    .input {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      opacity: 0;

      outline: none;
    }

    .indcator {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 60px;
      height: 60px;

      font-size: $font-size-large;
      font-weight: bold;

      line-height: 1em;
      text-transform: uppercase;

      border: 2px solid $color-light-blue;
      border-radius: 100%;

      background-color: $color-light-blue;
      @media only screen and (max-width: $breakpoint-medium) {
        width: 35px;
        height: 35px;

        font-size: $font-size-basis;
      }
      &.active {
        border-color: $color-blue;
      }
    }
  }
}
