@import '../../styles/utils/__utils';

$ball-size: 32px;

.container {
  margin: $spacing-small 0;
  max-width: 100%;

  .horizontalSlider {
    width: 100%;
    height: $ball-size;
    .track {
      top: 50%;
      bottom: 0;

      height: 2px;

      border-radius: $border-radius;

      background: $color-gray;

      transform: translateY(-50%);
    }
    .thumb {
      padding-right: $ball-size;
      width: $ball-size;
      height: $ball-size;

      cursor: grab;

      border-radius: 50%;

      outline: none;

      background-color: $color-blue;
      &:active {
        cursor: grabbing;
      }
    }
  }
}
