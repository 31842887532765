@import '../../../styles/utils/__utils';

.points {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: $text-width;

  text-align: center;
  .ranking {
    margin-bottom: $spacing-x-large;
    .title {
      margin-bottom: $spacing-xx-small;

      font-size: $font-size-large;
    }
    .motivation {
      font-size: $font-size-small;
    }
  }
  .ranking {
    margin-bottom: $spacing-x-large;
    .prompt {
      margin-bottom: $spacing-xx-small;

      font-size: $font-size-small;
    }
    .stars {
      display: flex;
      gap: $spacing-x-small;
      .star {
        padding: 0;

        cursor: pointer;

        font-size: $font-size-x-large;

        color: $color-dark-gray;

        border: none;

        background-color: transparent;
        &.active {
          color: $color-blue;
        }
      }
    }
  }
}
