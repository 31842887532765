@import '../../styles/utils/__utils';

.wrapper {
  &.stretch {
    width: 100%;
    .input {
      width: 100%;
    }
  }
  .title {
    display: block;

    margin-bottom: $spacing-xx-small;

    font-weight: bold;
  }
  .description {
    margin: 0;
    margin-bottom: $spacing-x-small;

    line-height: 1em;
  }
  .wrapper {
    position: relative;
    .input {
      padding: $spacing-x-small $spacing-small;

      resize: none;

      font-size: $font-size-small;

      color: $color-black;

      border: none;
      border-radius: 5px;

      outline: none;

      background-color: $color-light-blue;

      &.large {
        padding: $spacing-small $spacing-large;

        font-size: $font-size-basis;
      }
      &.centered {
        text-align: center;
      }
      &.uppercase {
        text-transform: uppercase;
      }
      &::placeholder {
        text-transform: none !important;
      }
      &:disabled {
        cursor: not-allowed;

        color: $color-dark-gray;

        opacity: 0.8;
      }
      &.alternative {
        border-bottom: 1px solid $color-black;
        border-radius: 0;

        background-color: transparent;
      }
    }
    .icon {
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: $spacing-small;
      height: 100%;

      pointer-events: none;

      color: $color-black;
    }
  }
}
