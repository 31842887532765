@import '../../../styles/utils/__utils';

.confetti {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;

  overflow: hidden;
}
.content {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: $spacing-small;
  width: 100%;
  max-width: $text-width;

  text-align: center;
  .list {
    display: flex;
    flex-direction: column;
    gap: $spacing-x-small;

    margin-top: $spacing-xx-large;
    margin-bottom: $spacing-large;
    padding: 0 $spacing-small;
    width: 100%;
    max-height: 60vh;

    overflow-y: auto;
    .legend,
    .player {
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
      gap: $spacing-small;

      padding: $spacing-x-small $spacing-medium;
    }
    .legend {
      padding-bottom: 0;

      .label {
        font-size: $font-size-x-small;

        text-align: left;
        &:last-child {
          text-align: right;
        }
      }
    }
    .player {
      border-radius: $border-radius;

      background-color: $color-gray;
      &:nth-child(2) {
        background-color: $color-yellow;
      }
      &:nth-child(3) {
        background-color: $color-green;
      }
      &:nth-child(4) {
        background-color: $color-light-green;
      }

      .place {
        font-weight: bold;

        text-align: left;
      }
      .name {
        text-align: left;
        text-transform: capitalize;
      }
      .score {
        font-weight: bold;

        text-align: right;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;

    width: 100%;
  }
}
