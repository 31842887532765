@import '../../../styles/utils/__utils';

.questions {
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-bottom: $spacing-x-small;
    padding: $spacing-small 0;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: $spacing-medium;

    padding-bottom: $spacing-small;
    @media only screen and (max-width: $breakpoint-small) {
      grid-template-columns: 1fr;
    }
    .panel {
      display: flex;
      flex-direction: column;

      padding: $spacing-small 0;
      width: 100%;

      border-radius: 5px;

      background-color: $color-white;
      .actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: $spacing-small;

        padding: $spacing-small $spacing-medium;
        &.center {
          justify-content: center;
        }
        .title {
          font-size: $font-size-large;
        }
      }
      .list {
        position: relative;

        flex: auto;

        margin: $spacing-small 0;
        min-height: 200px;

        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-track {
          border-radius: 0px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 0px;
        }
        .items {
          position: absolute;
          top: 0;
          left: 0;

          display: flex;
          flex-direction: column;
          gap: $spacing-x-small;

          padding: 0 $spacing-medium;
          width: 100%;
          height: 100%;

          overflow-y: auto;

          .question {
            position: relative;

            display: flex;
            justify-content: space-between;
            align-items: center;

            padding: $spacing-x-small;

            border: 2px solid $color-light-blue;
            border-radius: 5px;

            background-color: $color-light-blue;
            &:hover {
              border-color: $color-gray;
            }
            &.active {
              border-color: $color-blue;
            }
            .info {
              width: 100%;

              cursor: pointer;
              .index {
                font-size: $font-size-small;
              }
              .text {
                width: 100%;
                height: 24px;

                overflow: hidden;

                font-size: $font-size-small;
                font-weight: bold;
              }
            }
            &:hover {
              .move {
                .icon {
                  opacity: 1;
                }
              }
            }
            &:first-child {
              .move {
                .icon {
                  &:first-child {
                    display: none;

                    margin: 0;
                  }
                }
              }
            }
            &:last-child {
              .move {
                .icon {
                  &:last-child {
                    display: none;

                    margin: 0;
                  }
                }
              }
            }
            .move {
              position: absolute;
              right: 0;

              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;

              margin: $spacing-x-small;
              width: 50px;
              height: 50px;

              background-color: $color-light-blue;
              .icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                cursor: pointer;

                font-size: $font-size-large;

                color: $color-blue;
                line-height: 1em;

                opacity: 0;

                transition: 0.2s;
                &:first-child {
                  margin-bottom: -5px;
                }
                &:last-child {
                  margin-top: -5px;
                }
              }
            }
          }
        }
      }
    }
    .data {
      display: flex;
      flex-direction: column;

      padding-top: $spacing-x-small;
      width: 100%;

      overflow: hidden;

      border-radius: 5px;

      background-color: $color-white;
      .header {
        padding: $spacing-small $spacing-medium;
        padding-top: $spacing-large;

        .heading {
          font-size: $font-size-large;
        }
      }
      .form {
        display: flex;
        flex: auto;
        flex-direction: column;
        gap: $spacing-medium;

        padding: 0 $spacing-medium;
        padding-bottom: $spacing-small;

        .section {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 3fr;
          @media only screen and (max-width: $breakpoint-small) {
            grid-template-columns: 1fr;
          }
          .label {
            font-size: $font-size-x-small;
            font-weight: bold;
          }
          .input {
            position: relative;

            overflow: hidden;

            border-radius: 5px;

            input {
              padding: $spacing-x-small $spacing-small;
              width: 100%;

              font-size: $font-size-small;

              border: none;

              background-color: $color-light-blue;
            }

            &::after {
              position: absolute;
              top: 100%;
              left: 0px;

              width: 100%;
              height: 2px;

              content: '';

              border-radius: $border-radius;

              background-color: $color-light-blue;
            }
          }
          &:last-child,
          &.noBorder {
            .input {
              &::after {
                display: none;
              }
            }
          }
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $spacing-small;

        padding: $spacing-x-small $spacing-medium;

        border-radius: 0 0 5px 5px;

        background-color: $color-light-blue;
      }
    }
  }
}
