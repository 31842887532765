@import '../../../styles/utils/__utils';

.join {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: $modal-width;

  text-align: center;
  .title {
    font-size: $font-size-x-large;
    .room {
      text-transform: uppercase;
    }
    .name {
      text-transform: capitalize;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    .inputs {
      display: flex;
      flex-direction: column;
      gap: $spacing-small;

      margin: $spacing-x-large 0;
    }
  }
}
