@import '../../styles/utils/__utils';

.wrapper {
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;

  padding: $spacing-small;

  background-color: $color-gray;
  .content {
    width: 100%;
    max-width: $page-width;
  }
}
