@import '../../styles/utils/__utils';

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: $spacing-x-large;
  @media only screen and (max-width: $breakpoint-small) {
    flex-direction: column;
  }
  .picture {
    width: 280px;
    height: 280px;
    @media only screen and (max-width: $breakpoint-small) {
      width: 180px;
      height: 180px;
    }
  }
  .fields {
    display: flex;
    flex-direction: column;
    gap: $spacing-small;

    .buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: $spacing-x-small;
    }
  }
}
