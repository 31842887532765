@import '../../styles/utils/__utils';

.dropzone {
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  cursor: pointer;

  opacity: 0.4;
}

.wrapper {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 200px;

  overflow: hidden;

  border-radius: 5px;

  background-color: $color-light-blue;
  .loading {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    background-color: $color-light-blue;
  }
  .value {
    position: relative;

    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;

    height: 100%;

    cursor: pointer;

    .upload {
      font-weight: bold;

      color: $color-black;

      opacity: 0.3;
    }
    .image {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      cursor: default;

      object-fit: contain;
    }
    .delete {
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      margin: $spacing-small;
      padding: 0;
      width: 30px;
      height: 30px;

      cursor: pointer;

      font-size: $font-size-basis;

      color: $color-white;

      border: none;
      border-radius: 100%;

      background-color: $color-red;
      svg {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
