@import '../../../styles/utils/__utils';

.question {
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  .header {
    margin-top: $spacing-large;
    .players {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: $spacing-small;

      padding: $spacing-small;
      width: 100%;

      .player {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $spacing-x-small;

        padding: $spacing-xx-small $spacing-x-small;

        text-transform: capitalize;

        border-radius: $border-radius;

        background-color: $color-white;
        box-shadow: $box-shadow;
        .status {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 24px;
          height: 24px;

          color: $color-white;

          border-radius: 100%;

          background-color: $color-blue;

          transition: 0.2s;
          &.pending {
            color: $color-gray;

            background-color: $color-light-blue;
          }
        }
        .name {
          padding-right: $spacing-xx-small;

          font-size: $font-size-x-small;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: $spacing-small;
    padding: $spacing-large;
    width: 100%;
    .content {
      display: flex;

      width: 100%;
      max-width: $content-width;

      overflow: hidden;

      border-radius: $border-radius;

      background-color: $color-white;
      box-shadow: $box-shadow;
      .image {
        position: relative;

        width: 70%;
        img {
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          overflow: hidden;

          border-radius: $border-radius;

          background-color: $color-gray;

          object-fit: cover;
        }
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: $spacing-medium $spacing-large;
        width: 100%;
        .meta {
          margin-bottom: $spacing-large;
          .number {
            margin-bottom: $spacing-x-small;

            font-size: $font-size-x-small;
            font-weight: bold;
          }
          .title {
            font-size: $font-size-large;
            font-weight: bold;

            line-height: 1.3em;
          }
        }
      }
    }
  }
}
