@import '../../styles/utils/__utils';

.tile {
  position: relative;

  display: flex;
  flex-direction: column;

  &:hover {
    .menu {
      opacity: 1;
    }
  }
  .menu {
    position: absolute;
    top: 0;
    right: 0;

    z-index: 10;

    opacity: 0;

    transition: 0.2s;
    .toggle {
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      margin: $spacing-x-small;
      width: 40px;
      height: 40px;

      cursor: pointer;

      font-size: $font-size-large;

      border: none;
      border-radius: 100%;

      background-color: $color-light-blue;
      .close {
        font-size: $font-size-medium;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      gap: $spacing-xx-small;

      margin: $spacing-x-small;
      padding: $spacing-large;

      border-radius: 5px $border-radius 5px 5px;

      background-color: $color-white;
      &:not(.open) {
        display: none;
      }
      .link {
        padding: 0;
        max-width: none;

        cursor: pointer;

        text-align: left;

        border: none;

        background-color: transparent;
        &:hover {
          color: $color-blue;
          text-decoration: underline;
        }
      }
    }
  }

  .image {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    cursor: pointer;

    overflow: hidden;

    border-radius: 5px;

    background-color: $color-gray;
    &:after {
      display: block;

      padding-bottom: 100%;

      content: '';

      object-fit: cover;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
  .meta {
    margin-top: $spacing-x-small;

    cursor: pointer;
    .name {
      font-size: $font-size-basis;
      font-weight: bold;
    }
    .date {
      font-size: $font-size-small;
    }
  }
}
