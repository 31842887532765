@import '../../styles/utils/__utils';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-small;

  padding: $spacing-x-small $spacing-small;
  width: 100%;
  min-height: 60px;
  .noMobile {
    @media only screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }
}
