@import '../../styles/utils/__utils';

.text {
  .title {
    display: block;

    margin-bottom: $spacing-xx-small;

    font-weight: bold;
  }
  .description {
    margin: 0;

    line-height: 1em;
  }
}
