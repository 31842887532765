@import '../../styles/utils/__utils';

.tile {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 100%;

  overflow: hidden;

  border-radius: 5px;

  background-color: $color-dark-gray;
  &:hover {
    .image {
      transform: scale(1.1);
    }
  }
  .image {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    overflow: hidden;

    background-color: $color-gray;

    transition: 0.2s;

    object-fit: cover;
    &:after {
      display: block;

      padding-bottom: 100%;

      content: '';
    }
    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
  .content {
    position: absolute;
    bottom: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-content: flex-start;
    align-items: center;

    padding: $spacing-small;
    padding-top: $spacing-large;

    color: $color-white;
    text-align: left;

    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6867121848739496) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    .name {
      font-size: $font-size-basis;

      text-align: center;
    }
    .desc {
      font-size: $font-size-small;

      text-align: center;
    }
    .buttons {
      margin-top: $spacing-x-small;
    }
  }
}
