@import '../../styles/utils/__utils';

.hero {
  display: none;

  margin-bottom: $spacing-medium;
  width: 100%;
  height: 400px;
}
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: $spacing-x-large;

  width: 100%;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-x-small;
    .heading {
      font-size: $font-size-large;

      line-height: 1.6em;
      text-align: center;
    }
    .form {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: $spacing-x-small;
    }
  }
}
