@import '../../styles/utils/__utils';

.modal {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: $spacing-small;
  width: 100%;
  height: 100%;

  z-index: 1000;

  background-color: $color-glass;

  backdrop-filter: blur(2px);
}
