@import '../../../styles/utils/__utils';

.general {
  margin: auto;
  //max-width: $content-width;

  .header {
    display: flex;
    justify-content: space-between;

    margin-bottom: $spacing-x-small;
    padding: $spacing-small 0;
  }
  .data {
    display: flex;
    flex-direction: column;

    width: 100%;

    border-radius: 5px;

    background-color: $color-white;
    .header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: $spacing-small;

      padding: $spacing-medium;

      .title {
        font-size: $font-size-large;
      }
    }
    .form {
      display: flex;
      flex: auto;
      justify-content: stretch;
      gap: $spacing-xx-large;

      padding: 0 $spacing-medium;
      padding-bottom: $spacing-large;
      @media only screen and (max-width: $breakpoint-medium) {
        flex-wrap: wrap;
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: $spacing-medium;

        width: 100%;
        .section {
          .label {
            font-size: $font-size-small;
            font-weight: bold;
          }
          .input {
            margin: $spacing-x-small 0;
            .themes {
              display: flex;

              width: 100%;
              height: 238px;

              .theme {
                position: relative;

                flex: auto;

                margin: 0;
                padding: 0;
                width: 100%;

                cursor: pointer;

                overflow: hidden;

                border: none;

                background-color: $color-light-blue;
                .border {
                  position: absolute;
                  top: 0;
                  left: 0;

                  width: 100%;
                  height: 100%;

                  z-index: 10;

                  border: 2px solid transparent;

                  transition: 0.1s;
                }
                &:first-child {
                  border-radius: 5px 0 0 5px;
                  .border {
                    border-radius: 5px 0 0 5px;
                  }
                }
                &:last-child {
                  border-radius: 0 5px 5px 0;
                  .border {
                    border-radius: 0 5px 5px 0;
                  }
                }
                &:hover {
                  .border {
                    border: 2px solid $color-gray;
                  }
                }
                &.active {
                  .border {
                    border: 2px solid $color-blue;
                  }
                }
                img {
                  position: absolute;
                  top: 0;
                  left: 0;

                  width: 100%;
                  height: 100%;

                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: $spacing-small;

      padding: $spacing-x-small $spacing-medium;

      border-radius: 0 0 5px 5px;

      background-color: $color-light-blue;
      @media only screen and (max-width: $breakpoint-tiny) {
        justify-content: center;
      }
    }
  }
}
