@import '../../styles/utils/__utils';

.layout {
  position: relative;

  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;

  margin: auto;
  padding: $spacing-small;
  width: 100%;
  max-width: $page-width;

  &.fullscreen {
    min-height: calc(100vh - 200px);
  }
  &.center {
    justify-content: center;

    margin: auto;
  }
  &.small {
    max-width: $form-width;
  }
}
