@import '../../../styles/utils/__utils';

.header {
  top: 0;
  left: 0;

  display: flex;
  justify-content: space-between;

  width: 100%;
  height: 100%;
}
.content {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: $spacing-small;
  max-width: $text-width;

  text-align: center;

  .qr {
    display: flex;

    background-color: $color-white;
  }
  .instructions {
    margin: $spacing-large 0;

    font-size: $font-size-large;
    .code {
      text-transform: uppercase;
    }
  }
  .players {
    margin-bottom: $spacing-xx-large;

    .title {
      margin-bottom: $spacing-small;

      font-size: $font-size-basis;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: $spacing-x-small;

      padding: 0 $spacing-small;
      width: 100%;
      max-height: 25vh;

      overflow-y: auto;
      .player {
        padding: $spacing-x-small $spacing-small;

        text-transform: capitalize;

        border-radius: $border-radius;

        background-color: $color-light-blue;
      }
    }
  }
}
