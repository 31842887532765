@import '../../../styles/utils/__utils';

.animateIn {
  animation-name: intro;
  animation-duration: 0.5s;
  animation-iteration-count: 1;

  animation-fill-mode: forwards;

  @keyframes intro {
    0% {
      opacity: 0;

      transform: translateY(20px);
    }
    100% {
      opacity: 1;

      transform: translateY(0px);
    }
  }
}

.video {
  margin-bottom: -140px;
  width: 100%;
  max-width: 400px;

  border: 5px solid $color-white;

  outline: unset;

  box-shadow: inset 5px 5px 5px $color-white;
}

.points {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 280px;

  z-index: 10;
  .ranking {
    margin-bottom: $spacing-xx-large;

    text-align: center;
    .title {
      font-size: $font-size-large;
    }
    .motivation {
      font-size: $font-size-small;
    }
  }
  .stats {
    display: flex;
    justify-content: space-around;

    margin-bottom: $spacing-xx-large;
    width: 100%;
    .stat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 0 $spacing-small;
      width: 100%;
      &:not(:last-child) {
        border-right: 2px solid $color-gray;
      }
      .number {
        font-size: $font-size-large;
        font-weight: bold;
      }
      .name {
        font-size: $font-size-small;

        line-height: 1em;
      }
    }
  }
  .score {
    padding: $spacing-x-small;
    width: 100%;

    font-weight: bold;

    text-align: center;

    border-radius: 5px;

    background-color: $color-light-green;
  }
}
