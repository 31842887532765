@import '../../styles/utils/__utils';

.button {
  display: flex;
  align-items: center;

  padding: $spacing-x-small 0;

  cursor: pointer;

  font-size: $font-size-basis;
  font-weight: bold;

  color: $color-blue;
  line-height: 1em;

  border: none;

  background-color: transparent;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: $spacing-xx-small;

    font-size: $font-size-large;

    line-height: 1em;
  }

  &.active {
    text-decoration: underline;
  }
  &.small {
    font-size: $font-size-small;
  }
}
