@import '../../styles/utils/__utils';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;

  .map {
    position: absolute;
    top: -30px;
    left: 0;

    width: 100%;
    height: calc(100% + 60px);
  }
}

.marker {
  display: flex;
  justify-content: center;

  margin-top: -20px;
  width: 40px;
  height: 40px;

  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;

  animation-name: pin;
  animation-duration: 0.2s;
  animation-iteration-count: 1;

  animation-fill-mode: forwards;
  &:after {
    position: absolute;

    width: 100%;
    height: 100%;

    content: '';

    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;

    box-shadow: $box-shadow;

    transform: rotate(135deg);
  }

  &.solutionMarker {
    &:after {
      background-color: $color-red;
    }
  }
  &.playerMarker {
    &:after {
      background-color: $color-blue;
    }
    .tag {
      position: absolute;

      display: flex;
      justify-content: center;
      gap: $spacing-x-small;

      padding: $spacing-x-small $spacing-small;

      font-size: $font-size-basis;

      border-radius: $border-radius;

      background-color: $color-white;
      box-shadow: $box-shadow;

      transform: translateY(calc(-100% - #{$spacing-x-small}));

      animation-name: tag;
      animation-duration: 0.2s;
      animation-iteration-count: 1;

      animation-fill-mode: forwards;
      .name {
        text-transform: capitalize;
      }
      .score {
        font-weight: bold;
      }
    }
  }
}

@keyframes tag {
  0% {
    opacity: 0;

    transform: translateY(-100%);
  }
  100% {
    opacity: 1;

    transform: translateY(calc(-100% - #{$spacing-x-small}));
  }
}

@keyframes pin {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
