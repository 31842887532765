@import '../../../styles/utils/__utils';

.solution {
  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: $spacing-medium;
  width: 100%;
  height: 100%;
  .scores {
    padding: $spacing-large $spacing-medium;
    padding-top: $spacing-medium;
    width: 100%;
    max-width: 300px;

    border-radius: $border-radius;

    background-color: $color-white;
    box-shadow: $box-shadow;
    .title {
      margin-bottom: $spacing-small;

      font-size: $font-size-medium;
    }
    .players {
      display: flex;
      flex-direction: column;
      gap: $spacing-x-small;

      margin-bottom: $spacing-large;
      padding: 0 $spacing-xx-small;
      min-height: 30vh;
      max-height: 80vh;

      overflow-y: auto;
      .player {
        display: flex;
        justify-content: space-between;

        padding: $spacing-x-small;

        border-radius: 5px;

        background-color: $color-light-blue;
        .name {
          padding-right: $spacing-small;
          width: 50%;

          overflow: hidden;

          text-transform: capitalize;
        }
        .score {
          width: 50%;

          font-weight: bold;

          text-align: right;
        }
      }
    }
  }
}
