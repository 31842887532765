@import '../../styles/utils/__utils';

.loading {
  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: $spacing-small;
  width: 100%;
  height: 100%;

  z-index: 10000000;

  font-size: $font-size-medium;
  font-weight: bold;

  color: $color-black;
  text-align: center;

  background-color: $color-white;
  .animation {
    width: 100%;
    max-width: 400px;

    outline: unset;
  }
  .message {
    margin-top: -50px;

    z-index: 10;
  }
}
