@import '../../styles/utils/__utils';

.main {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100%;

  z-index: 1;
  &.background {
    background-color: $color-white;
  }
}
