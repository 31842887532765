@import '../../styles/utils/__utils';

.link {
  display: flex;
  align-items: center;
  gap: $spacing-small;
  .name {
    font-size: $font-size-small;

    text-transform: capitalize;
  }
  .image {
    width: 50px;
    height: 50px;
  }
}
