@import '../../styles/utils/__utils';

$dotSize: 30px;

.wrapper {
  position: relative;

  height: 350px;

  overflow: hidden;

  .map {
    position: absolute;
    top: -30px;
    left: 0;

    width: 100%;
    height: calc(100% + 60px);
  }
  .button {
    position: absolute;

    padding: $spacing-x-small;
    @media only screen and (max-width: $breakpoint-small) {
      right: 0;
      bottom: 0;
    }
  }
}

.marker {
  display: flex;
  justify-content: center;

  margin-top: -15px;
  width: 30px;
  height: 30px;

  &:after {
    position: absolute;

    width: 100%;
    height: 100%;

    content: '';

    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;

    background-color: $color-red;
    box-shadow: $box-shadow;

    transform: rotate(135deg);
  }
}
