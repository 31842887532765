@import '../../../styles/utils/__utils';

.lobby {
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: $text-width;
  .title {
    font-size: $font-size-large;

    text-align: center;
  }
  .animation {
    width: 100%;
    max-width: 400px;

    border: 10px solid $color-white;

    outline: unset;

    box-shadow: inset 10px 10px 10px $color-white;
  }
}
