* {
  box-sizing: border-box;
}

[data-whatintent='mouse'] *:focus,
[data-whatintent='touch'] *:focus {
  outline: none;
}

html {
  height: 100%;

  scroll-behavior: smooth;
}

body {
  margin: 0px;

  font-size: $font-size-basis;
  font-smooth: always;

  color: $color-black;

  background-color: $color-white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.screen-reader-only {
  position: absolute;

  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;

  overflow: hidden;
  clip: rect(0 0 0 0);

  border: 0;
}

input,
button,
textarea,
address {
  -webkit-appearance: none;
}

*::-webkit-media-controls-panel {
  display: none !important;

  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;

  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;

  -webkit-appearance: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  scrollbar-color: $color-blue $color-light-blue;
  scrollbar-width: auto;
}

body {
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 10px;

    background: $color-light-blue;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: $color-blue;
  }
}

.mapboxgl-ctrl-geocoder {
  margin-top: 40px !important;

  background-color: transparent;
  box-shadow: none !important;
  input {
    padding: 6px 35px !important;

    border-radius: $border-radius;
  }
}
