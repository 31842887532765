@import '../../styles/utils/__utils';

.noMobile {
  @media only screen and (max-width: $breakpoint-medium) {
    display: none;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-x-small;

  width: 100%;

  .title {
    width: 100%;
    .heading {
      margin-bottom: 0;
    }
  }
  .list {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 20px;
    padding: $spacing-small;
    padding-right: 0;
    padding-bottom: 0;
    width: 100%;
    height: calc(100vh - 350px);

    border-radius: 5px;

    background-color: $color-light-blue;
    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: $spacing-small;

      margin-bottom: $spacing-small;
      padding-right: $spacing-small;
      width: 100%;

      overflow-y: auto;

      @media only screen and (max-width: $breakpoint-medium) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (max-width: $breakpoint-small) {
        grid-template-columns: repeat(1, 1fr);
      }

      * {
        scrollbar-color: $color-blue $color-gray;
        scrollbar-width: auto;
      }

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 0px;

        background: $color-gray;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
      }
    }
  }
}
